*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  min-width: 320px;
  font-size: 18px;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include media-down(md) {
    font-size: 16px;
  }

  @include media-down(xs) {
    font-size: 14px;
  }
}

body {
  width: 100%;
  margin: 0;
  font-family: $montserrat;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: $black;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}

.container {
  width: 1166px;

  // @include wrap('flexbox');
  @include make-container();

  @include media-down(lg) {
    width: 991px;
  }

  @include media-down(md) {
    width: 767px;
  }

  @include media-down(sm) {
    width: 95%;
  }
}

.no-scroll {
  &--desktop {
    overflow: hidden;

    @include media-down(md) {
      touch-action: none;
    }
  }

  @include media-down(md) {
    touch-action: none;
    overflow: hidden !important;
  }
}

.react-dropdown-select-input {
  margin-left: 0 !important;
}

main {
  overflow: hidden;
}
