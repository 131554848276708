@mixin resetButton {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

@mixin make-container() {
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

@mixin media-up($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @media (min-width: $breakpoint-value) {
    @content;
  }
}

@mixin media-down($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @media screen and (max-width: ($breakpoint-value - 1)) {
    @content;
  }
}
