.timer {

  &__essence {
    display: flex;
    justify-content: space-between;
    max-width: 864px;
    margin: 0 auto;

    @include media-down(lg) {
      justify-content: space-around;
    }
  }

  &__item {
    width: 190px;
    height: 224px;
    box-shadow: 0px 8px 30px rgba(176, 195, 208, 0.3);
    border-radius: 8px;
    font-weight: bold;
    font-size: 104px;
    line-height: 127px;
    text-align: center;
    color: #283036;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include media-down(md) {
      width: calc(190px * .8);
      height: calc(224px * .8);
      font-size: 70px;
      line-height: 100px;
    }

    @include media-down(sm) {
      width: calc(190px * .5);
      height: calc(224px * .4);
      font-size: 50px;
      line-height: 70px;
    }

    &--days {
      color: #2ECC71;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 64px;
    line-height: 80px;
    text-align: center;
    color: #283036;
    margin-bottom: 24px;

    @include media-down(lg) {
      font-size: 50px;
      line-height: 70px;
    }

    @include media-down(sm) {
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__span {
    display: block;
    color: #F05540;
  }

  &__sub-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: #283036;
    margin-bottom: 72px;

    @include media-down(lg) {
      font-size: 30px;
      line-heihgt: 38px;
    }

    @include media-down(sm) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  &__essence {
    margin-bottom: 24px;
  }

  &__text {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #283036;

    &--pretext {
      margin-bottom: 24px;

    }
    &--aftertext {
      margin-bottom: 110px;

      @include media-down(sm) {
        margin-bottom: 16px;
      }
    }
  }

  &__button {
    height: 76px;
    width: 340px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2ECC71;
    border: 2px solid #16ca72;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    transition: 300ms ease-out;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
      background: #fff;
      color: #16ca72;
    }
  }

  &__num-label {
    position: absolute;
    bottom: 10%;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #BCBCBC;

    @include media-down(sm) {
      bottom: 5px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
