
.apply {
  $root: &;

  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #303740;
  background: rgba(48, 55, 64, 0.8);
  background: hsla(214, 14%, 22%, 0.8);
  justify-content: center;
  align-items: center;
  display: NONE;

  &.isOpened {
    display: flex;
  }

  &__essence {
    max-width: 640px;
    width: 100%;
    background-color: #fff;
    padding: 55px 80px 24px;
    position: relative;

    @include media-down(sm) {
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__overlay {
    position: absolute;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #283036;
    margin-bottom: 24px;

    @include media-down(sm) {
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 10px;
    }
  }

  &__button {
    height: 56px;
    width: 100%;
    max-width: 416px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2ECC71;
    border: 2px solid #16ca72;
    border-radius: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    transition: 300ms ease-out;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
      background: #fff;
      color: #16ca72;
    }
  } /* eof &__button */

  &__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    color: #303740;
    transition: color 300ms ease-out;

    &--checkbox {
      display: block;
      color: #283036;
      margin-bottom: 48px;

      @include media-down(sm) {
        margin-bottom: 16px;
      }
    }
  }

  &__checkbox {
    margin-right: 16px;

    margin-right: 1rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-width: 25px;
    height: 25px;
    border: 2px solid #16ca72;
    box-sizing: border-box;
    border-radius: 2px;
    -webkit-transition: background .5s,border .5s;
    transition: background .5s,border .5s;

    &--checked {
      background: #16ca72;
      border: 2px solid #16ca72!important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    width: 100%;
    background: #fff;
    border: 2px solid #2ECC71;
    border-radius: 2px;
    color: #303740;
    padding: 16px 18px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #283036;
    transition: border 300ms ease-out;

    @include media-down(sm) {
      padding: 8px 16px;
    }
  }

  &__message {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    color: #F05540;
    display: none;
  }

  &__item {
    margin-bottom: 16px;
    height: 108px;

    @include media-down(sm) {
      margin-bottom: 0;
      height: 92px;
    }

    &--checkbox {
      display: flex;
      height: auto;
    }

    &--error {

      #{$root}__label {
        color: #F05540;
      }

      #{$root}__input {
        border: 2px solid #F05540;
      }

      #{$root}__message {
        display: block;
      }
    }
  }
}
