// colors
$black: #303740;
$darkBlack: #333333;
$white: #fff;
$dirtyWhite: #e5e5e5;
$tomato: #f05540;
$gray: #d8d8d8;
$darkGray: #9b9b9b;
$green: #16ca72;
$lightgreen: #dcf6e9;

// fonts
$montserrat: 'Montserrat', sans-serif;

//grid
$grid-columns: 12 !default;
$grid-gutter-width: 32px !default;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1920px
);

$flexiblegs-breakpoint: (
  "xl" : "",
  "lg" : "(max-width: 1024px)",
  "md" : "(max-width: 768px)",
  "sm" : "(max-width: 667px)"
);

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
