
.container--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}

.header {

  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.09);
  margin-bottom: 48px;

  &__logo {

    &:hover {
      cursor: pointer;
    }
  }

  &-socials {

    &__item {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

