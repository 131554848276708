
.thx {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #303740;
  background: rgba(48, 55, 64, 0.8);
  background: hsla(214, 14%, 22%, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  display: NONE;

  &.isOpened {
    display: flex;
  }

  &__essence {
    max-width: 640px;
    width: 100%;
    background-color: #fff;
    padding: 55px 16px 24px;
    position: relative;

    @include media-down(sm) {
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__overlay {
    position: absolute;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #283036;
    margin-bottom: 24px;
  }

  &__tomato-man {
    margin: 0 auto 48px;
    display: block;
  }

  &__button {
    height: 56px;
    width: 100%;
    max-width: 416px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2ECC71;
    border: 2px solid #16ca72;
    border-radius: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    transition: 300ms ease-out;
    margin: 0 auto;
    margin-bottom: 40px;

    &:hover {
      cursor: pointer;
      background: #fff;
      color: #16ca72;
    }
  }
}

.thx-more {
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #283036;
    margin-bottom: 8px;
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
